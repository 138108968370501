import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookSquare, faGooglePlusSquare, faLinkedin} from '@fortawesome/fontawesome-free-brands'
import {faGlobe} from '@fortawesome/pro-solid-svg-icons'

const Testimonials = ({listOfTestimonials}) => {
  const renderTestimonials = (testimonials) => {
    return testimonials.map(({node: testimonial}, index) =>
      <div key={index} className='col-xs-12 col-sm-6'>
        <div className='text-center p-5'>
          <a href={testimonial.social[0].url} target='_blank' rel="noopener noreferrer">
            <img
              src={testimonial.avatar.childImageSharp.fluid.src}
              className='rounded-circle avatar'
              alt={testimonial.name}
            />
          </a>
          <blockquote className='no-icon'>{testimonial.message}</blockquote>
          <div>
            <p className='m-0'><strong>{testimonial.name}</strong></p>
            <p className='text-sm text-muted'>{`${testimonial.role} at ${testimonial.company}`}
              <span className='d-none d-sm-inline social-icons'/>
              <br/>
              {
                testimonial.social.map((social, index) =>
                  <a key={index}
                     href={social.url}
                     className='link-unstyled'
                     target='_blank'
                     rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={getIcon(social.icon)} style={{margin: '0.1rem'}} />
                  </a>
                )
              }
            </p>
          </div>
        </div>
      </div>
    )
  }

  const getIcon = (iconName) => {
    switch(iconName){
      case 'google+':
        return faGooglePlusSquare
      case 'fb':
        return faFacebookSquare
      case 'linkedin':
        return faLinkedin
      default:
        return faGlobe
    }
  }

  return (
    <section id="testimonials-clients" className="testimonials container">
      <div className="row">
        {renderTestimonials(listOfTestimonials)}
      </div>
    </section>
  )
}

export default Testimonials