import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Video from '../common/Video';
import Slogan from './Slogan';
import smoothScroll from 'scroll-to-element';
import { scrollToForm } from '../contact/ContactForm';

const Intro = ({ bgImage, video }) => (
  <section id="intro" className="jumbotron bg-dark">
    <div id="video-fallback" className="background">
      <Img resolutions={bgImage} className="background img-fluid" />
    </div>
    <Video src={video} />
    <div id="video-container" className="background d-none d-md-block" />
    <div className="container">
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-10 col-sm-12">
          <Slogan />

          <h2 className="intro-description seo-lead">
            <strong>We’re a process-driven software development company from Poland.</strong> We help businesses to
            connect with their audiences using high-end web and mobile apps.
          </h2>
          <p>
            <Link
              to="#contact-partial"
              id="get-intro-contact"
              className="btn btn-primary animate-scroll mb-2 mb-sm-0"
              role="button"
              onClick={event => {
                event.preventDefault();
                scrollToForm(null, -90);
              }}
            >
              Build your app with us
            </Link>
            <span className="d-inline d-sm-none" style={{ padding: '0 7px'}} />
            <span className="text-white d-none d-sm-inline" style={{ padding: '0 7px' }}>
              or
            </span>
            <Link
              to="#services"
              id="get-intro-learnmore"
              className="btn btn-outline-light animate-scroll mb-2 mb-sm-0"
              role="button"
              onClick={event => {
                event.preventDefault();
                smoothScroll('#services', { offset: -90, duration: 500 });
              }}
            >
              See our services
            </Link>
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Intro;
