import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCircle,
  faDollarSign,
  faEuroSign,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons'
import axios from 'axios'

class CtaInfoPack extends React.Component {
  STATE_READY = 'Get infopack'
  STATE_SENDING = 'Sending...'
  STATE_SENT = 'Infopack sent'
  STATE_ERROR = 'Try again'

  constructor (props) {
    super(props)
    this.state = {
      email: null,
      current: this.STATE_READY,
    }
  }

  render() {
    const { infoPackUrl } = this.props

    return (
      <section className="partial-cta-infopack py-5">
        <div className="container">
          <div className="row">
            <div className="col-11 col-sm-11 offset-sm-0 col-md-6 offset-md-0 col-lg-5 offset-lg-1 col-xs-12 offset-xs-0">
              <div className="infopack-headline">
                <div className="infopack-headline-text">
                  <h1 className="mt-0">
                    hourly
                    <br />
                    <strong>rates</strong>
                  </h1>
                </div>
                <div className="infopack-headline-symbol">
                  <span className="infopack-headline-symbol-1 fa-stack">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="fa-stack-2x text-warning"
                    />
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className="fa-stack-1x text-white"
                    />
                  </span>
                  <span className="infopack-headline-symbol-2 fa-stack">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="fa-stack-2x text-info"
                    />
                    <FontAwesomeIcon
                      icon={faEuroSign}
                      className="fa-stack-1x text-white"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-10 offset-1 col-sm-12 offset-sm-0 col-md-5 col-lg-5 col-xs-12 offset-xs-0 text-center text-sm-left">
              <p className="mb-3">
                <strong>Grab our infopack</strong>
                <br />
                to learn more about prices and services
              </p>
              <form
                ref={r => (this.formRef = r)}
                action="#"
                name="infopack_form"
                id="infopack-form"
                onSubmit={event => {
                  this.setState({ current: this.STATE_SENDING })
                  event.preventDefault()
                  axios
                    .post(infoPackUrl, { email: this.state.email })
                    .then(() => {
                      this.setState({
                        email: null,
                        current: this.STATE_SENT,
                      })
                      this.formRef.reset()
                    })
                    .catch(() => {
                      this.setState({ current: this.STATE_ERROR })
                    })
                }}
              >
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your e-mail"
                    name="email"
                    autoComplete="email"
                    onChange={e => this.setState({ email: e.target.value })}
                    required
                  />
                  <span className="input-group-append">
                    <button
                      className="btn btn-info"
                      type="submit"
                      name="send_button"
                    >
                      {this.state.current === this.STATE_SENDING && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          style={{ marginRight: '0.5rem' }}
                        />
                      )}
                      {this.state.current === this.STATE_SENT && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: '0.5rem' }}
                        />
                      )}
                      {this.state.current}
                    </button>
                  </span>
                </div>
                <div className="form-group form-check mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="infopack-form-gdpr-consent"
                    name="gdpr_consent"
                    required
                  />
                  <label
                    className="form-check-label small text-muted"
                    htmlFor="infopack-form-gdpr-consent"
                  >
                    I agree to receive an infopack from Zaven Sp. z.o.o. The
                    consent is voluntary and I can withdraw it any time,
                    stopping further processing of my data.
                  </label>
                </div>

                {this.state.current === this.STATE_ERROR && (
                  <p
                    id="form-feedback-invalid"
                    className="mt-1 text-sm text-danger"
                  >
                    <em>Please make sure your email address is valid</em>
                  </p>
                )}
                {this.state.current === this.STATE_SENT && (
                  <p
                    id="form-feedback-success"
                    className="mt-1 text-sm text-muted"
                  >
                    <em>
                      You can check your e-mail now. If you have difficulties
                      finding it, you may want to look in your SPAM directory.
                    </em>
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CtaInfoPack
