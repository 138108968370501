import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

class Video extends Component {
  componentDidMount() {
    this.loadVideo();
  }

  /**
   * Renders video
   */
  loadVideo = () => {
    const container = document.getElementById('video-container');

    if (!container) return;

    const videoElement = document.createElement('video');
    videoElement.autoplay = true;
    videoElement.muted = true;
    videoElement.loop = true;
    videoElement.src = this.props.src;
    videoElement.id = 'intro-video';
    videoElement.classList.add('fadeOut');

    container.appendChild(videoElement);

    // Fade in animation after 2 seconds
    setTimeout(() => {
      if (videoElement) {
        videoElement.classList.add('fadeIn');
        videoElement.classList.remove('fadeOut');
      }
    }, 2000);
  };

  render() {
    if (isMobile) return null;

    return <div id="video-container" className="background d-none d-md-block" />;
  }
}

export default Video;
