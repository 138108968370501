import React from 'react';
import NavBar from '../components/common/NavBar';
import Intro from '../components/home/Intro';
import video from '../assets/images/intro.mp4';
import Services from '../components/home/Services';
import Technologies from '../components/common/Technologies';
import CtaInfoPack from '../components/home/CtaInfoPack';
import SelectedProjectsContainer from '../components/home/SelectedProjectsContainer';
import ProcessDiagram from '../components/home/ProcessDiagram';
import CtaMobile from '../components/common/CtaMobile';
import Layout from '../layouts/index';
import Testimonials from '../components/home/Testimonials';
import Divider from '../components/common/Divider';
import RecommendedArticles from '../components/home/RecommendedArticles';
import ContactBottom from '../components/contact/ContactBottom';
import { graphql } from 'gatsby';

const Index = ({ data, location }) => {
  const { legalName } = data.info;
  const { city, street, country, url } = data.info.address.office;
  const { email, phone, phoneClean, skype, skypeName } = data.info.contact;
  const testimonials = data.testimonialsHome.edges;
  const technologies = data.technologies_home.edges;
  const workTime = data.info.contact.workTimeUTC;
  const projects = data.projects_home.edges;
  const articles = data.allBlogJson.edges;
  const image = data.bgImage.resolutions;
  const mailingConfig = data.mailing_info_home;

  return (
    <Layout>
      <NavBar fixedPosition transparentBackground phoneNumber={phone} email={email} location={location} />
      <Intro bgImage={image} video={video} />
      <Services />
      <Technologies listOfTechnologies={technologies} />
      <CtaInfoPack infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`} />
      <SelectedProjectsContainer listOfProjectNodes={projects} />
      <ProcessDiagram />
      <CtaMobile />
      <Testimonials listOfTestimonials={testimonials} />
      <Divider />
      <RecommendedArticles listOfArticles={articles} newsletterUrl={`${process.env.GATSBY_MAILING_URL}/api/newsletter`} />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTime}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
        mailingConfig={mailingConfig}
      />
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query dataQuery {
    info: dataJson {
      ...Contact
    }
    bgImage: imageSharp(fluid: { originalName: { regex: "/intro_bg/" } }) {
      resolutions(width: 1920, height: 1080, quality: 100) {
        ...GatsbyImageSharpResolutions
      }
    }
    technologies_home: allTechnologiesJson {
      ...Technologies
    }
    projects_home: allProjectsYaml(filter: { displayIn: { eq: "home" } }) {
      ...SelectedProjects
    }
    testimonialsHome: allTestimonialsClientsJson(filter: { displayIn: { eq: "home" } }) {
      edges {
        node {
          name
          company
          role
          message
          avatar {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          social {
            url
            icon
          }
        }
      }
    }
    allBlogJson {
      edges {
        node {
          title
          excerpt
          thumbnailUrl
          url
        }
      }
    }
  }
`;
