import React from 'react';
import { Link } from 'gatsby';
import imageOutsourcingServices from '../../assets/images/services_outsourcing.png';
import imageMobileServices from '../../assets/images/services_mobile.png';
import imageWebServices from '../../assets/images/services_web.png';

import { Container, Row, Col, Card } from 'reactstrap';

const Services = () => (
  <section id="services">
    <Container>
      <Row>
        <Col>
          <h1 className="text-center text-lowercase services-title">
            <strong>
              Web solutions <span className="text-nowrap">& mobile apps</span>
            </strong>
            <br />
            for your next project
          </h1>
        </Col>
      </Row>
    </Container>
    <div className="target-card-deck">
      <hr className="rainbow m-0 hr-bg" />
      <Container>
        <Row>
          <Col sm="12" md="4">
            <Card className="target-card target-card-3">
              <div className="card-header text-center">
                <div className="card-title">
                  <h2>IT companies</h2>
                  <p className="target-card-slogan">Scale up your team</p>
                  <p>
                    <em>Hire app developers without barriers</em>
                  </p>
                </div>
              </div>
              <div className="card-block d-flex align-items-center">
                <div className="card-text flex-grow-1">
                  <Row>
                    <Col sm="12">
                      <div className="d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-sm-start">
                        <img src={imageOutsourcingServices} alt="Outsourcing development" style={{ height: '75px' }} />
                        <div className="text-left ml-3 flex-grow-1">
                          <p className="seo-h4">IT Outsourcing</p>
                          <p>
                            <Link to="/services/poland-software-outsourcing" className="btn btn-outline-success btn-sm">
                              Learn more
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="12" md="8">
            <Card className="target-card target-card-1">
              <div className="card-header text-center">
                <div className="card-title">
                  <h2>Startups</h2>
                  <p className="target-card-slogan">Prove your concept</p>
                  <p>
                    <em>MVP shipped in 12-16 weeks with full team support</em>
                  </p>
                </div>
              </div>
              <div className="card-block d-flex align-items-center">
                <div className="card-text flex-grow-1">
                  <Row>
                    <Col sm="6">
                      <div className="d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-sm-start">
                        <img src={imageMobileServices} alt="Mobile development" style={{ height: '75px' }} />
                        <div className="text-left ml-3 flex-grow-1">
                          <p className="seo-h4">Mobile development</p>
                          <p>
                            <Link
                              to="/services/mobile-app-development-poland"
                              className="btn btn-outline-extra1 btn-sm"
                            >
                              Learn more
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-sm-start">
                        <img src={imageWebServices} alt="Web development" style={{ height: '75px' }} />
                        <div className="text-left ml-3 flex-grow-1">
                          <p className="seo-h4">Web development</p>
                          <p>
                            <Link to="/services/web-app-development-poland" className="btn btn-outline-info btn-sm">
                              Learn more
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default Services;
