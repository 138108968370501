import React, { Component } from 'react'
import Typed from '../../../node_modules/typed.js/lib/typed'

class Slogan extends Component {
  componentDidMount() {
    const options = {
      strings: ['engage', 'connect', 'deliver', 'communicate', 'scale', ''],
      startDelay: 2000,
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2500,
      cursorChar: '_',
      onComplete: function () {
        secondTyped()
      },
    }

    this.typed = new Typed('#verb', options)

    const secondTyped = () => {
      const { startDelay, onComplete, strings, ...restOfOptions } = options
      this.typed.destroy()

      this.typed2 = new Typed('#verb', {
        ...restOfOptions,
        strings: [...options.strings.slice(0, options.strings.length - 1)],
        loop: true,
      })
    }
  }

  componentWillUnmount() {
    this.typed2 ? this.typed2.destroy() : this.typed.destroy()
  }

  render() {
    return (
      <p className="intro-slogan">
        apps that
        <br />
        <span id="verb" />
      </p>
    )
  }
}

export default Slogan
