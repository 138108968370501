import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/pro-light-svg-icons'
import axios from 'axios'
import {faSpinner} from "@fortawesome/pro-solid-svg-icons"

class RecommendedArticles extends React.Component {
  STATE_READY = 'Subscribe'
  STATE_SENDING = 'Sending...'
  STATE_SENT = 'Subscribed!'
  STATE_ERROR = 'Try again'

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      current: this.STATE_READY,
    }
  }

  formRef

  renderArticles = articles => {
    return articles.map(({ node: article }, index) => <div key={index} className="card card-flat mb-2">
        <a href={article.url} target="_blank" className="blog-recommended-image" rel="noopener noreferrer">
          <img src={article.thumbnailUrl} alt={article.title} className="card-img-top" />
        </a>
        <div className="py-1">
          <h4 className="blog-recommended-title">
            <a href={article.url} target="_blank" className="link-unstyled" rel="noopener noreferrer">
              {article.title}
            </a>
          </h4>
          <p className="text-muted text-sm">{article.excerpt}</p>
          <a href={article.url} target="_blank" className="btn btn-sm btn-outline-secondary" rel="noopener noreferrer">
            Read this article
          </a>
        </div>
      </div>);
  }

  render() {
    const { listOfArticles, newsletterUrl } = this.props

    return (
      <section id="blog-recommended">
        <div className="container">
          <div className="blog-recommended-list">
            <p className="seo-h2 mb-5">Recommended articles</p>
            <div className="card-deck-wrapper">
              <div className="card-deck">
                {this.renderArticles(listOfArticles)}

                <div className="card text-center card-outline blog-recommended-newsletter mb-2">
                  <div className="card-body">
                    <div className="mt-5 mb-4">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size="3x"
                        className="text-muted mb-2"
                      />
                      <p className="card-title seo-h4">
                        Stay in touch with us!
                      </p>
                      <p className="card-text text-muted">
                        Leave us your e-mail to get new articles from our blog
                      </p>
                    </div>
                    <form
                      ref={r => this.formRef = r}
                      action="#"
                      method="post"
                      className="form"
                      name="footer_newsletter_form"
                      id="blog-recommended-newsletter-form"
                      onSubmit={event => {
                        this.setState({ current: this.STATE_SENDING })
                        event.preventDefault()
                        axios
                          .post(newsletterUrl, { email: this.state.email })
                          .then(() => {
                            this.setState({
                              email: null,
                              current: this.STATE_SENT,
                            })
                            this.formRef.reset()
                          })
                          .catch(() => {
                            this.setState({
                              current: this.STATE_ERROR,
                            })
                          })
                      }}
                    >
                      <div className="form-group">
                        <input
                          className="text-center form-control form-control-success"
                          type="email"
                          name="email"
                          onChange={event => this.setState({email: event.target.value})}
                          placeholder="Enter your e-mail"
                          id="blog-recommended-newsletter-input"
                          required
                        />
                      </div>
                      <div className="form-group form-check mt-2 text-left">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="blog-recommended-newsletter-gdpr-consent"
                          name="gdpr_consent"
                          required
                        />
                        <label
                          className="form-check-label small text-muted"
                          htmlFor="blog-recommended-newsletter-gdpr-consent"
                        >
                          I agree to receive a newsletter from Zaven Sp. z.o.o.
                          The consent is voluntary and I can withdraw it any
                          time, stopping further processing of my data.
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-outline-primary"
                        name="send_button"
                      >
                        {this.state.current === this.STATE_SENDING && (
                          <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: '0.5rem'}} />
                        )}
                        {this.state.current}
                      </button>
                      {/* Could be error handling, if needed */}
                      {/*<p*/}
                        {/*className="text-center-xs mb-0"*/}
                        {/*style={{ marginTop: '5px' }}*/}
                      {/*>*/}
                        {/*<small className="text-muted newsletter-hint">*/}
                          {/*&nbsp; ERROR DESCRIPTION */}
                        {/*</small>*/}
                      {/*</p>*/}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default RecommendedArticles
